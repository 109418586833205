/*------------------------------------*\
    #FONTS
\*------------------------------------*/
.fBold {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}
.fSemiBold {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}
.fRegular {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.fLight {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/*------------------------------------*\
    #GENERAL
\*------------------------------------*/
.container {
  width: calc(100% - 66px);
  margin: 33px auto;
  max-width: 1400px;
  height: calc(100% - 66px);
}
.clear {
  clear: both;
}
/*------------------------------------*\
    #PLUGINS STYLES
\*------------------------------------*/
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #686976;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover {
  background-color: #686976;
}
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #686976;
}
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e7e7ec;
}
.mCSB_inside > .mCSB_container {
  margin-right: 16px;
}
/*------------------------------------*\
    #GENERAL
\*------------------------------------*/
html {
  width: 100%;
  height: 100%;
}
.log-bg {
  background: url('../img/sgir/bg_blue.jpg') no-repeat center;
  width: 100%;
  height: 100%;
  background-size: cover !important;
}
.log-bg.log-bg--on {
  background: url('../img/sgir/bg_blue.jpg') no-repeat center;
}
/*------------------------------------*\
    #LOGIN
\*------------------------------------*/
.login {
  height: 100%;
  width: 100%;
  text-align: center;
  display: table;
}
.login__wrap {
  display: table-cell;
  vertical-align: middle;
}
.login__form {
  width: 340px;
  margin: 0 auto;
}
.login__header {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  letter-spacing: 4px;
  box-sizing: border-box;
  width: 230px;
  float: right;
  text-align: left;
  color: #fff;
  font-size: 32px;
  padding-bottom: 40px;
}
.login__header-span {
  color: #fecc00;
}
.login__group {
  padding: 6px 0;
}
.login__label-wrap {
  float: left;
  width: calc(100% - 230px);
  text-align: right;
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
}
.login__label {
  width: calc(100% - 45px);
  color: #fff;
  display: block;
  float: left;
  text-align: right;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 13px;
}
.login__input {
  padding: 0 12px;
  height: 36px;
  line-height: 36px;
  background: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #313245;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 230px;
  box-sizing: border-box;
}
.login__input:focus {
  outline: none;
}
.login__img-wrap {
  text-align: center;
  display: block;
  float: left;
  width: 45px;
}
.login__img {
  display: block;
  margin: 8px auto 0 auto;
}
.login__body {
  padding-bottom: 30px;
}
.login__submit {
  cursor: pointer;
  background: #fecc00;
  width: 135px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
  transition: 300ms;
  margin-bottom: 25px;
}
.login__submit:focus,
.login__submit:hover {
  outline: none;
}
.login__submit:hover {
  background: #d0a700;
}
.login__buttons {
  width: 230px;
  float: right;
  text-align: left;
}
.login__forgot {
  color: #fff;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 12px;
  transition: 300ms;
}
.login__forgot:hover {
  color: #06bae9;
}
.login__foter {
  padding-top: 25px;
  width: 100%;
}
.login__footer-line {
  margin-top: 25px;
  border-top: 1px solid #fff;
  width: 100%;
}
.login__footer-wrap {
  margin-top: 16px;
  width: 100%;
  float: right;
  text-align: center;
}
.login__footer-img {
  width: 345px;
  max-width: 100%;
}
.login__email-icon {
  color: #fecc00;
  font-size: 23px;
  font-weight: bold;
}
.login__pre-header {
  text-align: left;
  margin-left: 96px;
  padding: 2px 0;
}
.login__pre-header-people {
  width: 80px;
}
/*------------------------------------*\
    #ALERT
\*------------------------------------*/
.alert {
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box;
  padding-left: 109px;
}
.alert--warning {
  color: red;
}
/*------------------------------------*\
    #STATUTE
\*------------------------------------*/
.statute {
  margin-top: 20px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.4;
  text-align: left;
}
.statute a {
  color: #fecc00;
}
@media all and (max-width: 750px) {
  .login {
    display: table;
  }
  .login__header {
    width: calc(100% - 80px);
    text-align: center;
  }
  .login__form {
    width: calc(100% - 40px);
  }
  .login__label-wrap {
    width: 80px;
  }
  .login__input {
    width: calc(100% - 80px);
  }
  .login__buttons {
    width: auto;
    float: none;
    display: inline-block;
  }
  .login__footer-wrap {
    float: none;
    width: auto;
    text-align: center;
  }
  .login__forgot {
    width: 135px;
    text-align: center;
    display: block;
  }
}
/*------------------------------------*\
    #COOKIE-BAR
\*------------------------------------*/
#cookie-bar {
  background: #36353b;
  padding: 7px 0;
}
#cookie-bar p {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
}
#cookie-bar a {
  display: inline;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  transition: color 300ms;
}
#cookie-bar a:hover {
  color: #06bae9;
}
#cookie-bar .cb-enable {
  background: #fecc00;
  padding: 0 6px;
  text-transform: uppercase;
  transition: background 300ms;
  text-decoration: none;
  margin-left: 8px;
}
#cookie-bar .cb-enable:hover {
  color: #fff;
  background: #fecc00;
}
