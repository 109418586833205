/*------------------------------------*\
    #FONTS
\*------------------------------------*/

.fBold {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}
.fSemiBold {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}
.fRegular {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.fLight {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}


/*------------------------------------*\
    #COLORS
\*------------------------------------*/


@yellow: #fecc00;
@dark: #313245;
@blue: #06bae9;
@orange: #fab218;
@green: #25c940;
@gray: #9a9eab;
@background: fade(#2f3043, 80);
@darkFont: #303144;
@grayFont: #9798a9;



/*------------------------------------*\
    #GENERAL
\*------------------------------------*/


.container {
  width: ~'calc(100% - 66px)';
  margin: 33px auto;
  max-width: 1400px;
  height: ~'calc(100% - 66px)';
}

.clear {
  clear: both;
}




/*------------------------------------*\
    #PLUGINS STYLES
\*------------------------------------*/


.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #686976;
  &:hover {
    background-color: #686976;
  }
}

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #686976;
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e7e7ec;
}

.mCSB_inside>.mCSB_container {
  margin-right: 16px;
}

/*------------------------------------*\
    #GENERAL
\*------------------------------------*/


html {
  width: 100%;
  height: 100%;
}

.log-bg {
  background: url('../img/sgir/bg_blue.jpg') no-repeat center;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  &.log-bg--on {
    background: url('../img/sgir/bg_blue.jpg') no-repeat center;
  }
}





/*------------------------------------*\
    #LOGIN
\*------------------------------------*/


.login {
  height: 100%;
  width: 100%;
  text-align: center;
  display: table;

  &__wrap {
    display: table-cell;
    vertical-align: middle;
  }

  &__form {
    width: 340px;
    margin: 0 auto;
  }

  &__header {
    .fBold;
    letter-spacing: 4px;
    box-sizing: border-box;
    width: 230px;
    float: right;
    text-align: left;
    color: #fff;
    font-size: 32px;
    padding-bottom: 40px;
  }

  &__header-span {
    color: @yellow;
  }

  &__group {
    padding: 6px 0;
  }

  &__label-wrap {
    float: left;
    width: ~'calc(100% - 230px)';
    text-align: right;
    box-sizing: border-box;
    height: 36px;
    line-height: 36px;
  }

  &__label {
    width: ~'calc(100% - 45px)';
    color: #fff;
    display: block;
    float: left;
    text-align: right;
    .fRegular;
    font-size: 13px;
  }

  &__input {
    padding: 0 12px;
    height: 36px;
    line-height: 36px;
    background: #fff;
    .fRegular;
    color: @dark;
    border: none;
    border-radius: 4px;
    outline: none;
    width: 230px;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  &__img-wrap {
    text-align: center;
    display: block;
    float: left;
    width: 45px;
  }

  &__img {
    display: block;
    margin: 8px auto 0 auto;
  }

  &__body {
    padding-bottom: 30px;
  }

  &__submit {
    cursor: pointer;
    background: @yellow;
    width: 135px;
    height: 35px;
    line-height: 35px;
    text-align:  center;
    border: none;
    border-radius: 4px;
    color: #fff;
    .fBold;
    font-size: 14px;
    text-transform: uppercase;
    transition: 300ms;
    margin-bottom: 25px;
    &:focus, &:hover {
      outline: none;
    }
    &:hover {
      background: darken(@yellow, 9);
    }
  }

  &__buttons {
    width: 230px;
    float: right;
    text-align: left;
  }

  &__forgot {
    color: #fff;
    text-decoration: none;
    .fLight;
    font-size: 12px;
    transition: 300ms;
    &:hover {
      color: @blue;
    }
  }

  &__foter {
    padding-top: 25px;
    width: 100%;
  }

  &__footer-line {
    margin-top: 25px;
    border-top: 1px solid #fff;
    width: 100%;
  }

  &__footer-wrap {
    margin-top: 16px;
    width: 100%;
    float: right;
    text-align: center;
  }

  &__footer-img {
    width: 345px;
    max-width: 100%;
  }

  &__email-icon {
    color: @yellow;
    font-size: 23px;
    font-weight: bold;
  }

  &__pre-header {
    text-align: left;
    margin-left: 96px;
    padding: 2px 0;
  }

  &__pre-header-people {
    width: 80px;
  }
}




/*------------------------------------*\
    #ALERT
\*------------------------------------*/


.alert {
  color: #fff;
  .fRegular;
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box;
  padding-left: 109px;
}

.alert--warning {
  color: red;
}


/*------------------------------------*\
    #STATUTE
\*------------------------------------*/

.statute {
  margin-top: 20px;
  color: #fff;
  .fRegular;
  font-size: 11px;
  line-height: 1.4;
  text-align: left;
  a {
    color: @yellow;
  }
}


@media all and (max-width: 750px) {

  .login {
    display: table;

    &__header {
      width: ~'calc(100% - 80px)';
      text-align: center;
    }

    &__form {
      width: ~'calc(100% - 40px)';
    }

    &__label-wrap {
      width: 80px;
    }

    &__input {
      width: ~'calc(100% - 80px)';
    }

    &__buttons {
      width: auto;
      float: none;
      display: inline-block;
    }

    &__footer-wrap {
      float: none;
      width: auto;
      text-align: center;
    }

    &__forgot {
      width: 135px;
      text-align: center;
      display: block;
    }
  }

}

/*------------------------------------*\
    #COOKIE-BAR
\*------------------------------------*/

#cookie-bar {
  background: #36353b;
  padding: 7px 0;
}

#cookie-bar p {
  .fRegular;
  font-size: 12px;
  color: #fff;
}

#cookie-bar a {
  display: inline;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  transition: color 300ms;
  &:hover {
    color: #06bae9;
  }
}

#cookie-bar .cb-enable {
  background: @yellow;
  padding: 0 6px;
  text-transform: uppercase;
  transition: background 300ms;
  text-decoration: none;
  margin-left: 8px;
  &:hover {
    color: #fff;
    background: @yellow;
  }
}
